import React from "react"

import { Swiper, SwiperSlide } from "swiper/react"
import type { SwiperOptions } from "swiper"

import Container from "@ecom/ui/components/Container"
import Card from "./Card"

import * as styles from "./advsSwiper.module.scss"

import piggy_bank from "./img/piggy_bank.svg"
import more_money from "./img/more_money.svg"
import parental_control from "./img/parental_control.svg"

export interface Item {
  img: typeof piggy_bank
  head: string
  desc: string
}

const ITEMS = [
  {
    img: piggy_bank,
    head: "Карманные расходы",
    desc: "Отправляйте ребенку деньги онлайн и контролируйте его траты",
  },
  {
    img: more_money,
    head: "Первый заработок",
    desc: "Ставьте ребенку задачи и устанавливайте вознаграждения за их выполнение",
  },
  {
    img: parental_control,
    head: "Дети под присмотром",
    desc: "Узнайте, где находится ваш ребенок через приложение",
  },
]

const params: SwiperOptions = {
  slidesPerView: "auto",
  loop: true,
  breakpoints: {
    // when window width is >= Xpx
    960: {
      allowTouchMove: false,
    },
  },
}

export default function AdvsSwiper() {
  return (
    <Container>
      <Swiper {...params} className={styles.swiper}>
        {ITEMS.map((data, i) => (
          <SwiperSlide className={styles.slide} key={i}>
            <Card {...data} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Container>
  )
}
