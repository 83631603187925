import React from "react"

import { Swiper, SwiperSlide } from "swiper/react"

import * as styles from "./swiperScreen.module.scss"

import map_scr from "./img/map_scr.png"
import main_scr from "./img/main_scr.png"
import calendar_scr from "./img/calendar_scr.png"

const ITEMS_SCREEEN = [main_scr, map_scr, calendar_scr]

export default function SwiperScreen(props: any) {
  return (
    <Swiper {...props} className={styles.swiperImg}>
      {ITEMS_SCREEEN.map((src, i) => (
        <SwiperSlide key={i}>
          <img src={src} alt="phone screen" className={styles.sliderImg} />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
