/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react"
import clsx from "clsx"
import * as styles from "./footerDetails.module.scss"

type Props = {
  ligal?: string
  isRedButton?: boolean
  withFixedButton?: boolean
  copyright: any
}

export const FooterDetails = ({
  ligal = "",
  isRedButton,
  withFixedButton = true,
  copyright,
}: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  return (
    <div className={withFixedButton ? styles.wrapperLarge : styles.wrapperSmall} id="footer">
      <div
        className={clsx(styles.text, !expanded && styles.hide)}
        dangerouslySetInnerHTML={{ __html: ligal }}
        id="ligal"
      />
      <button
        type="button"
        onClick={() => setExpanded((prev) => !prev)}
        className={clsx(styles.button, isRedButton ? styles.redButton : "")}
      >
        {expanded ? "Скрыть" : "Подробнее"}
      </button>

      <div className={styles.block}>
        <p className={styles.text}>{copyright}</p>
      </div>
    </div>
  )
}
