import React, { useEffect, useState } from "react"
import { useLocation } from "@gatsbyjs/reach-router"
import { themeBlack } from "@ecom/ui/style/themeBlack"
import getSearchParam from "@ecom/ui/utils/getSearchParam"
import IconVK from "@ecom/ui/components/Footer/SocialLinks/IconVK"
import IconOK from "@ecom/ui/components/Footer/SocialLinks/IconOK"
import IconTelegram from "@ecom/ui/components/Footer/SocialLinks/IconTelegram"

import BaseLayout, { BaseLayoutProps } from "./base"
import BaseHeader, { HeaderProps } from "../Headers/Main"
import Footer from "../Footer"

import { setIDBValue } from "../../utils/idbUtils"
import { SEOProps } from "../seo"
import FooterMainPage from "../FooterMainPage"

export interface PageLayoutProps extends BaseLayoutProps {
  ligal?: string
  copyright?: React.ReactNode
  phones?: HeaderProps["phones"]
  noHeader?: boolean
  noFooter?: boolean
  buttonText?: string
  btnClick?: () => void
  seoData?: SEOProps
  withNav?: boolean
  noPhone?: boolean
  noIndex?: boolean
  isOrder?: boolean
}

const customSocialLinks = [
  {
    href: "https://vk.com/halvacard",
    Icon: IconVK,
  },
  {
    href: "https://ok.ru/group/53179535065217",
    Icon: IconOK,
  },
  {
    href: "https://t.me/halvacard_official",
    Icon: IconTelegram,
  },
]

const customStoreLinks = {
  appStore: "https://pwa.sovcombank.ru",
  googlePlay: "https://pwa.sovcombank.ru",
  appGallery: "https://pwa.sovcombank.ru",
}

export default function MainPageLayout({
  children,
  phones = ["8 (800) 100-77-72"],
  ligal,
  copyright,
  theme,
  noHeader = false,
  noFooter = false,
  buttonText,
  btnClick,
  seoData = {},
  withNav = false,
  noPhone = false,
  noIndex = false,
  favicon,
  isOrder = false,
}: PageLayoutProps) {
  const location: Location = useLocation()

  const [newPhones, setNewPhones] = useState(phones)
  const [withPhones, setWithPhones] = useState(!noPhone)
  let socialLinks = null
  let storeLinks = null

  if (process.env.GATSBY_PATH_PREFIX === "/order") {
    socialLinks = customSocialLinks
    storeLinks = customStoreLinks
  }

  const { title, description, titleH1, url, shortTitle } = seoData

  useEffect(() => {
    if (getSearchParam("utm_source") === "E-com" && getSearchParam("utm_medium") === "PKW") {
      setNewPhones(["8 (800) 100-19-06"])
    }
    if (
      getSearchParam("utm_medium") === "Partner" &&
      getSearchParam("utm_source") === "postomat_halva" &&
      getSearchParam("utm_campaign") === "MPP_applications_postomat" &&
      getSearchParam("utm_content") === "qrcode"
    ) {
      setWithPhones(false)
    }
    if (
      location.pathname.includes("reissue") ||
      (location.pathname.includes("invitefriend") &&
        getSearchParam("utm_medium") === "Marketing" &&
        getSearchParam("utm_source") === "halvaapp2" &&
        getSearchParam("utm_campaign") === "invitefriend" &&
        getSearchParam("utm_term") === "dk_fk_invitefriend" &&
        getSearchParam("code") === "code")
    ) {
      setNewPhones(["8 (800) 700-66-96"])
    }
  }, [location.pathname])

  useEffect(() => {
    if (theme === themeBlack) {
      setIDBValue("theme", "black")
      document.documentElement.style.setProperty("--primary-color", "#0a0a0a")
      document.documentElement.style.setProperty("--text-color", "#0a0a0a")
    } else {
      setIDBValue("theme", "red")
      document.documentElement.style.setProperty("--primary-color", "#ff4e50")
      document.documentElement.style.setProperty("--text-color", "#292929")
    }
  }, [theme])

  const footerMode = isOrder ? (
    <FooterMainPage
      socialLinks={socialLinks}
      storeLinks={storeLinks}
      phones={newPhones}
      shortText={ligal}
      withPhone={withPhones}
      copyright={copyright}
    />
  ) : (
    <Footer
      socialLinks={socialLinks}
      storeLinks={storeLinks}
      phones={newPhones}
      shortText={ligal}
      withPhone={withPhones}
      copyright={copyright}
    />
  )

  return (
    <BaseLayout
      title={title}
      description={description}
      titleH1={titleH1}
      url={url}
      shortTitle={shortTitle}
      theme={theme}
      noIndex={noIndex}
      favicon={favicon}
    >
      {!noHeader && (
        <BaseHeader
          phones={newPhones}
          withPhone={withPhones}
          buttonText={buttonText}
          btnClick={btnClick}
          withNav={withNav}
        />
      )}
      {children}
      {!noFooter && footerMode}
    </BaseLayout>
  )
}
