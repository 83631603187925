import React from "react"

import { Swiper, SwiperSlide } from "swiper/react"

import * as styles from "./swiperText.module.scss"

const ITEMS_TEXT = [
  {
    head: "Отправление запросов",
    desc: "Если у Вашего ребенка закончились деньги на карте, он сможет отправить Вам запрос на необходимую сумму, который моментально придет Вам push-уведомлением. Перевод денег осуществляется без комиссии.",
  },
  {
    head: "Определение местоположения",
    desc: "Вы сможете в настоящем времени увидеть местоположение ребенка на карте. Теперь нет повода для беспокойства!",
  },
  {
    head: "Планировщик задач",
    desc: "Эта функция поможет Вам и Вашему ребенку запланировать дела на день, неделю и месяцы вперед. По Вашему желанию за выполненную задачу можно отправить ребенку денежное вознаграждение.",
  },
]

export default function SwiperText(props: any) {
  return (
    <Swiper
      {...props}
      pagination={{
        clickable: true,
        progressbarOpposite: true,
        bulletClass: styles.bulletClass,
        bulletActiveClass: styles.bulletActiveClass,
      }}
      className={styles.swiperText}
    >
      {ITEMS_TEXT.map(({ head, desc }, i) => (
        <SwiperSlide key={i}>
          <h3 className={styles.head}>{head}</h3>
          <p className={styles.desc}>{desc}</p>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
