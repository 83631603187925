import React from "react"
import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import Img from "./Img"
import * as styles from "./banner.module.scss"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"

const buttonText = "Оформить карту"

type BannerProps = {
  orderNum?: string
}

export default function Banner({ orderNum }: BannerProps) {
  return (
    <section className={styles.section} data-exclude={orderNum}>
      <Container className={styles.container}>
        <div>
          <Img alt="bg image" className={styles.img} />
          <h1 className={styles.head}>
            Детская карта
            <br />
            Халвёнок
          </h1>
          <p className={styles.desc}>
            Детская карта Халвёнок
            <br className="d-md-none" /> от Совкомбанка
          </p>
          <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
            {buttonText}
          </Button>
        </div>
      </Container>
    </section>
  )
}
