import React from "react"

import Container from "@ecom/ui/components/Container"

import * as styles from "./fastReg.module.scss"

import h_char from "./img/h_char.svg"

type FastRegProps = {
  orderNum?: string
}

export default function FastReg({ orderNum }: FastRegProps) {
  return (
    <section className={styles.root} data-exclude={orderNum}>
      <Container className={styles.container}>
        <div>
          <h2 className={styles.head}>
            Оформите карту
            <br />
            за 5 минут
          </h2>
          <p className={styles.desc}>
            и заберите в ближайшем
            <br />
            отделении банка
          </p>
        </div>
        <img alt="h char" src={h_char} className={styles.h_char} />
        <div className={styles.bg} />
      </Container>
    </section>
  )
}
