import React, { useEffect, useState } from "react"

import UIFooter from "@ecom/ui/components/Footer"

import { defaultCopyright, defaultSocialLinks } from "./helpers"
import { FooterDetails } from "./FooterDetails"

export type FooterProps = {
  shortText?: string
  phones?: string[]
  copyright?: React.ReactNode
  withPhone?: boolean
  socialLinks?: any
  storeLinks?: any
}

const classes = {
  footer: "content-visibility-auto",
}

export default function FooterMainPage({
  phones = ["8 (800) 100-77-72"],
  shortText,
  copyright = defaultCopyright,
  withPhone,
  socialLinks = defaultSocialLinks,
  storeLinks,
}: FooterProps) {
  const [phoneDelay, setPhoneDelay] = useState(false)

  useEffect(() => {
    setPhoneDelay(true)
  }, [])

  return (
    <UIFooter
      shortText={<FooterDetails copyright={copyright} ligal={shortText} />}
      phone={phones}
      withPhone={phoneDelay && withPhone}
      socialLinks={socialLinks}
      storeLinks={storeLinks}
      classes={classes}
    />
  )
}
