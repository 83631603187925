import { DLClickEvent } from "../DLEvents/DLClickEvent"
import scrollToPersonalForm from "../../scrollToPersonalForm"

const handleClickBanner = (name: "sticky" | "click_cta", withoutScroll = false) => {
  DLClickEvent({ name, placement: "banner" })
  if (withoutScroll) return
  scrollToPersonalForm()
}

export { handleClickBanner }
