import React from "react"

import type { Item } from "../AdvsSwiper"

import * as styles from "./card.module.scss"

export default function Card({ img, head, desc }: Item) {
  return (
    <>
      <img src={img} alt={head} height="136" width="136" />
      <h3 className={styles.head}>{head}</h3>
      <p className={styles.desc}>{desc}</p>
    </>
  )
}
