import React from "react"

import { navigate } from "gatsby"

import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import sendRequest from "@ecom/ui/utils/sendRequest"

import { setIDBValue } from "../../utils/idbUtils"

import { PersonalInfoForm, PersonalInfoFormProps } from "./Base"
import { events } from "../../helpers/events"

interface Response {
  requestId: string
  status: "pending" | "repeated" | "approve" | "reject" | "error"
  partnerExternalId: number
}

type Props = Omit<PersonalInfoFormProps, "onSubmit">

export function PersonalInfoShortForm(props: Props) {
  const { frame } = props
  function handleSubmit(valuesToRequest: Record<string, any>) {
    sendRequest(`${process.env.GATSBY_APP_API_URL}/v2/app/public/start`, {
      ...valuesToRequest,
      phoneValidate: true,
    })
      .then(({ status, requestId }: Response) => {
        if (["pending", "repeated", "reject", "waitingDecision", "approve"].includes(status)) {
          setIDBValue("requestId", requestId)
          return Promise.resolve(status)
        }

        return Promise.reject(new Error(status))
      })

      .then((status: Response["status"]) => {
        switch (status) {
          case "reject":
            navigate("/error-reject")
            pushToDataLayer(events.applicationSend.reject)
            return
          case "repeated":
            navigate("/error-repeat")
            pushToDataLayer(events.applicationSend.repeated)
            return
          case "error":
            throw Error(status)
          case "approve":
            pushToDataLayer(events.applicationSend.approve)
            break
          default:
            break
        }

        if ((window as any)?.ym) {
          ;(window as any).ym(process.env.GATSBY_YANDEX_ID, "reachGoal", "ApplicationSend")
        }
        // Для Frame
        if (frame) {
          const formSentMessage = JSON.stringify({ action: "FORM_SENT" })
          window.parent.postMessage(formSentMessage, "*")
        }

        navigate("/verify/")
      })
      .catch(() => {
        pushToDataLayer(events.applicationSend.error)
        pushToDataLayer(events.GAFormEvent.error)
        navigate("/error-technical/")
      })
  }

  return (
    <PersonalInfoForm {...props} onSubmit={handleSubmit} name="shortPersonalForm" frame={frame} />
  )
}
