import type { IEvents } from "../interfaces/dataLayer"

export const events: IEvents = {
  applicationSend: {
    approve: { event: "applicationSend", result: "success" },
    error: { event: "applicationSend", result: "error" },
    reject: { event: "applicationSend", result: "error", reason: "reject" },
    repeated: { event: "applicationSend", result: "error", reason: "repeated" },
  },
  GAFormEvent: {
    error: {
      event: "GAFormEvent",
      eventCategory: "server_error",
      eventAction: "error_order",
      eventLabel: globalThis.location?.href,
    },
    continue: {
      event: "GAFormEvent",
      name: "Продолжить",
      placement: "appl_halva",
      eventAction: "deliveryForm",
    },
  },
  dadata: {
    success: { event: "dadata", eventCategory: "success", eventAction: "order" },
    error: { event: "dadata", eventCategory: "error", eventAction: "order" },
  },
  buttonClick: (name) => ({
    appl: { event: "buttonClick", name, placement: "appl_halva" },
    calc: { event: "buttonClick", name },
  }),
  smsverification: {
    fail: {
      event: "smsverification",
      result: "fail",
    },
    success: {
      event: "smsverification",
      result: "success",
    },
    exceeded: {
      event: "smsverification",
      result: "exceeded",
    },
    sended: {
      event: "smsverification",
      result: "sended",
    },
    resend: {
      event: "smsverification",
      result: "resend",
    },
    timeout: {
      event: "smsverification",
      result: "timeout",
    },
  },
}
