import React from "react"

export const QUESTIONS = [
  [
    <>Не получается привязать карту ребенку (не отображаются карты).</>,
    <>
      Перепроверьте номер телефона, паспортные данные и дату рождения в настройках – они должны
      совпадать с теми данными, которые вы указывали при оформлении карты.
    </>,
  ],
  [
    <>
      Что делать, если мы привязали не ту карту? (взамен старой утерянной ребенком карты пришла
      новая)
    </>,
    <>
      В приложении есть возможность перепривязать карту. Для этого необходимо нажать на стрелочку
      рядом с картой, выбрать пункт «Отвязать карту». Затем необходимо привязать нужную.
    </>,
  ],
  [
    <>Как настроить пуш-уведомление на совершение транзакций?</>,
    <>
      На данный момент пуш-уведомления на транзакции не предусмотрены, мы подумаем над тем, чтобы в
      будущем добавить и пуши.
    </>,
  ],
  [
    <>Почему приложение не показывает местоположение ребенка?</>,
    <>
      Приложение определяет местоположение в том случае, если соблюдены условия на телефоне ребенка:
      <ul>
        <li>В настройках приложения разрешен доступ к геопозиции.</li>
        <li>Включена передача геоданных на устройстве.</li>
        <li>Есть доступ к интернету.</li>
      </ul>
    </>,
  ],
  [
    <>Как пополнить карту?</>,
    <>
      Бесплатно:
      <ul>
        <li>
          Наличными в устройствах самообслуживания в любом из 2000 отделений Совкомбанка по всей
          стране.
        </li>
        <li>
          С карты Совкомбанка онлайн: через мобильное приложение «Халвенок» или «Совкомбанк-Халва»,
          а также в личном кабинете на сайте{" "}
          <a
            style={{ color: "inherit" }}
            rel="noreferrer"
            href="https://halvacard.ru/"
            target="_blank"
          >
            halvacard.ru
          </a>
        </li>
      </ul>
      С комиссией:
      <ul>
        <li>С карт других банков онлайн.</li>
        <li>Наличными в устройствах самообслуживания отделений любых банков по всей стране.</li>
      </ul>
    </>,
  ],
  [
    <>Как начисляется кэшбэк?</>,
    <>
      В партнерской сети:
      <ul>
        <li>
          при оплате телефоном:
          <ul>
            <li>6% — за каждую покупку от 10 000 руб.;</li>
            <li>4% — за каждую покупку от 5 000 до 9 999 руб.;</li>
            <li>2% — за каждую покупку до 4 999 руб.;</li>
          </ul>
        </li>
        <li>2% — при оплате картой.</li>
      </ul>
      Не у партнеров:
      <br />
      1% — за каждую покупку от 1 000 руб. (при любом способе оплаты).
    </>,
  ],
  [
    <>Почему я не могу произвести оплату через пэйпас?</>,
    <>
      Чтобы карта работала через пэйпас, воспользуйтесь банкоматом. Достаточно просто ввести один
      раз пин-код.
    </>,
  ],
  [
    <>Как я могу подключить СМС-уведомления о транзакциях по карте, и сколько это будет стоить?</>,
    <>
      СМС-уведомления бесплатны на протяжении всего срока пользования картой. Эта услугу можно
      подключить в офисах банка, по номеру 8-800-100-777-2, а также в приложениях
      &quot;Халвенок&quot; и &quot;Совкомбанк-Халва&quot;.
    </>,
  ],
  [
    <>
      Подскажите, по карте Халвенок не удается настроить бесконтактную оплату ни у меня, ни у
      ребенка в приложении Google(Samsung, apple) pay, почему?
    </>,
    <>
      Необходимо подключить смс-информирование. Эта услугу можно подключить в офисах банка, по
      номеру 8-800-100-777-2, а также в приложениях &quot;Халвенок&quot; и
      &quot;Совкомбанк-Халва&quot;.
    </>,
  ],
  [
    <>Как получать доход от хранения своих средств на карте?</>,
    <>
      Ставку можно увеличить до 5%, соблюдая условие минимального оборота: от 5 ежемесячных покупок*
      (их общая сумма должна составлять не мене 10 000 рублей). Ставка 5% действует для суммы до 300
      000 рублей. Для сумм свыше 300 000 руб. при соблюдении минимального оборота максимальная
      ставка — 4%.
    </>,
  ],
]
