import React from "react"

import Container from "@ecom/ui/components/Container"

import * as styles from "./advantages.module.scss"

import img from "./img/img.png"

const getItems = (rate: string) => [
  {
    text: "",
    num: "0",
    char: "₽",
    desc: (
      <>
        Бесплатное
        <br />
        обслуживание карты
      </>
    ),
  },
  {
    text: "до",
    num: `${rate}`,
    char: "%",
    desc: (
      <>
        Начисление на остаток
        <br />
        по карте
      </>
    ),
  },
  { text: "", num: "6", char: "%", desc: <>До 6% кэшбэк</> },
]

type AdvantagesProps = {
  rate: string
  orderNum?: string
}

export default function Advantages({ rate, orderNum }: AdvantagesProps) {
  return (
    <section className={styles.root} data-exclude={orderNum}>
      <Container>
        <h2 className={styles.head}>
          Наши
          <br className="d-sm-none" /> преимущества
        </h2>
        <img src={img} alt="boy" className={styles.img} />
        <ul className={styles.items}>
          {getItems(rate).map(({ text, num, char, desc }, i) => (
            <li key={i}>
              <div className={styles.item}>
                <h4 className={styles.sup}>{text}</h4>
                <h3>{num}</h3>
                <h4 className={styles.sup}>{char}</h4>
              </div>
              <p className={styles.desc}>{desc}</p>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
