import React, { useState, useEffect } from "react"

import type { SVGAttributes } from "react"

import Header from "@ecom/ui/components/Header/HeaderContainer"

import scrollToPersonalForm from "../../helpers/scrollToPersonalForm"
import { DLClickEvent } from "../../helpers/WatcherDL/DLEvents/DLClickEvent"

const handleClick = () => {
  DLClickEvent({ name: "click_cta", placement: "header" })
  scrollToPersonalForm()
}

const buttonProps = {
  onClick: handleClick,
}
const options = {
  rootMargin: "0px 0px -50% 0px",
  threshold: 0,
}

export type HeaderProps = {
  children?: React.ReactNode
  phones?: string[]
  Logo?: ((props: SVGAttributes<SVGElement>) => JSX.Element) | (() => JSX.Element)
  onLogoClick?: Function
  withButton?: boolean
  classes?: any
}

export function CustomLogo({
  children,
  phones = ["8 (800) 100-77-72"],
  Logo,
  onLogoClick,
  withButton = true,
  classes,
}: HeaderProps) {
  const [isSticky, setIsSticky] = useState(false)
  const phoneProps = {
    phones,
    classes,
  }

  function callback(entries: IntersectionObserverEntry[]) {
    const [entry] = entries
    setIsSticky(!entry.isIntersecting)
  }

  useEffect(() => {
    const isSupported =
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    let observer: IntersectionObserver
    const targetEl = document.getElementById("app")

    if (isSupported) {
      observer = new IntersectionObserver(callback, options)

      if (targetEl && isSupported) {
        observer.observe(targetEl)
      }
    }

    return () => {
      if (targetEl && isSupported) {
        observer.unobserve(targetEl)
      }
    }
  }, [])

  return (
    <Header
      withButton={withButton}
      PhoneProps={phoneProps}
      classes={classes}
      ButtonProps={buttonProps}
      sticky={isSticky}
      Logo={Logo}
      onLogoClick={onLogoClick}
    >
      {children}
    </Header>
  )
}
