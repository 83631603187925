import { decode } from "html-entities"

import { PageData } from "../interfaces/pageProps"

export function getPageData({ pageDataJson, admin }: PageData) {
  const { title, description, conditions } = pageDataJson || {}

  const ligal = admin?.page?.ligal?.text
  const phones =
    Array.isArray(admin?.page?.phones) && admin?.page!.phones?.length > 0
      ? admin?.page?.phones
      : undefined

  const noIndex = admin?.page?.notIndex

  const result = {
    // чтобы сработало значение по-умолчанию нужен undefined вместо null
    ligal: ligal ? decode(ligal) : undefined,
    phones: phones || undefined,
    seoData: { title, description } || {},
    title: title || undefined,
    description: description || undefined,
    conditions,
    noIndex,
  }

  return result
}
