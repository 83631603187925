import React, { useState } from "react"

import SwiperCore, { Controller, Pagination, Swiper } from "swiper"
import IconAppstore from "@ecom/ui/components/Footer/StoreLinks/IconAppstore"
import IconGPlay from "@ecom/ui/components/Footer/StoreLinks/IconGPlay"
import IconAppGallery from "@ecom/ui/components/Footer/StoreLinks/IconAppGallery"
import Container from "@ecom/ui/components/Container"
import Box from "@material-ui/core/Box"
import SwiperScreen from "./SwiperScreen"
import SwiperText from "./SwiperText"

import * as styles from "./moreOpts.module.scss"

import qr from "./img/qr.svg"

SwiperCore.use([Controller, Pagination])

const params = {
  slidesPerView: 1,
  loop: true,
}

type MoreOptsProps = {
  orderNum?: string
}

export default function MoreOpts({ orderNum }: MoreOptsProps) {
  const [firstSwiper, setFirstSwiper] = useState<Swiper>()
  const [secondSwiper, setSecondSwiper] = useState<Swiper>()

  return (
    <section className={styles.root} data-exclude={orderNum}>
      <Container>
        <h2>
          Больше возможностей
          <br />
          в мобильном приложении
          <br />
          «Халвенок»
        </h2>
        <div className={styles.swiperOuter}>
          <SwiperScreen
            {...params}
            onSwiper={setFirstSwiper}
            controller={{ control: secondSwiper }}
          />
          <div className={styles.right}>
            <SwiperText
              {...params}
              onSwiper={setSecondSwiper}
              controller={{ control: firstSwiper }}
            />
            <Box display="flex" justifyContent="space-evenly" className={styles.icons}>
              <Box display="flex" flexDirection="column" justifyContent="space-between">
                <IconAppstore />
                <IconGPlay />
                <IconAppGallery />
              </Box>
              <img alt="qr code" src={qr} />
            </Box>
          </div>
        </div>
      </Container>
    </section>
  )
}
