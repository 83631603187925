import React from "react"

import clsx from "clsx"

import Button from "@material-ui/core/Button"

import * as styles from "./navBtn.module.scss"

type Props = {
  className?: string
}

export default function NavBtn({ className }: Props) {
  return (
    <Button className={clsx(styles.btn, className)}>
      <svg viewBox="0 0 12 12" className={styles.arrowIcon}>
        <path d="M3.705 0l-1.41 1.41L6.875 6l-4.58 4.59L3.705 12l6-6z" />
      </svg>
    </Button>
  )
}
