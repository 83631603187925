import React, { useState, useEffect } from "react"

import Header from "@ecom/ui/components/Header/Halva"

import scrollToPersonalForm from "../../helpers/scrollToPersonalForm"
import { DLClickEvent } from "../../helpers/WatcherDL/DLEvents"

const options = {
  rootMargin: "0px 0px -50% 0px",
  threshold: 0,
}

export type HeaderProps = {
  children?: React.ReactNode
  phones?: string[]
  buttonText?: string
  withPhone?: boolean
  withNav?: boolean
  btnClick?: () => void
}

type PhoneType = {
  phones: string[]
  phoneHint?: string
}

export default function HeaderMain({
  children,
  phones = ["8 (800) 100-77-72"],
  buttonText = "Оформить",
  withPhone,
  btnClick,
  withNav = false,
}: HeaderProps) {
  const [isSticky, setIsSticky] = useState(false)
  const [phoneProps, setPhoneProps] = useState<PhoneType>({
    phones: [],
    phoneHint: "",
  })

  useEffect(() => {
    if (phones.length !== 0 && phoneProps.phones.length === 0) {
      setPhoneProps({
        phones,
        phoneHint: undefined,
      })
    }
  }, [phones, phoneProps])

  const handleClick = () => {
    DLClickEvent({ name: "sticky", placement: "header" })
    scrollToPersonalForm()
  }

  const buttonProps = {
    onClick: btnClick ?? handleClick,
  }

  const onLogoClick = () => {
    window.open("https://halvacard.ru/", "_blank")
  }

  function callback(entries: IntersectionObserverEntry[]) {
    const [entry] = entries
    setIsSticky(!entry.isIntersecting)
  }

  useEffect(() => {
    const isSupported =
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    let observer: IntersectionObserver
    const targetEl = document.getElementById("app")

    if (isSupported) {
      observer = new IntersectionObserver(callback, options)

      if (targetEl && isSupported) {
        observer.observe(targetEl)
      }
    }

    return () => {
      if (targetEl && isSupported) {
        observer.unobserve(targetEl)
      }
    }
  }, [])

  return (
    <Header
      withNav={withNav}
      withButton
      withPhone={withPhone}
      PhoneProps={phoneProps}
      ButtonProps={buttonProps}
      sticky={isSticky}
      onLogoClick={onLogoClick}
      buttonText={buttonText}
    >
      {children}
    </Header>
  )
}
