import React from "react"
import clsx from "clsx"
import LazyLoad from "react-lazyload"

import Box from "@material-ui/core/Box"

import * as styles from "./card.module.scss"

import type { Partner } from "../helpers/fetchPartners"

export default function Card({ brand, icon_url, period }: Partner) {
  return (
    <Box boxShadow={6} className={clsx(styles.card, "text-center")}>
      <Box display="flex" alignItems="center" justifyContent="center" className={styles.logo}>
        <LazyLoad offset={100} once>
          <img alt={brand} src={icon_url} />
        </LazyLoad>
      </Box>
      <h5 className={styles.title}>{brand}</h5>
      <p className={styles.term}>{period} мес.</p>
    </Box>
  )
}
