import React from "react"
import { graphql, PageProps } from "gatsby"

import { HalvenokLogo } from "../components/Halvenok/Header"
import Layout from "../components/Layouts/mainPage"
import Banner from "../components/Halvenok/Banner"
import AdvsSwiper from "../components/Halvenok/AdvsSwiper"
import FastReg from "../components/Halvenok/FastReg"
import Advantages from "../components/Halvenok/Advantages"
import MoreOpts from "../components/Halvenok/MoreOpts"
import Partners from "../components/Partners"
import PartnersHeader from "../components/Halvenok/PartnersHeader"
import FAQ from "../components/Halvenok/FAQ"
import { PersonalInfoShortForm } from "../components/PersonalInfoForm/ShortApp"
import type { PersonalInfoFormProps } from "../components/PersonalInfoForm"

import { PageData } from "../interfaces/pageProps"

import { getPageData } from "../helpers/getPageData"

const halvenokLabels: PersonalInfoFormProps["labels"] = {
  fio: "Фамилия, Имя и Отчество (родителя)",
  birthDate: "Дата рождения (родителя)",
  phone: "Телефон (родителя)",
}

export default function HalvenokPage({ data }: PageProps<PageData>) {
  const { ligal, phones, noIndex } = getPageData(data)
  const { halvaOstatok } = data.admin

  if (!halvaOstatok) {
    throw new Error("The halvaOstatok variable is required!!!")
  }

  return (
    <Layout noHeader ligal={ligal} phones={phones} noIndex={noIndex}>
      <HalvenokLogo />
      <Banner orderNum="1" />
      <AdvsSwiper />
      <FastReg orderNum="3" />
      <PersonalInfoShortForm title={null} labels={halvenokLabels} orderNum="4" />
      <Advantages rate="5" orderNum="5" />
      <MoreOpts orderNum="6" />
      <Partners title={<PartnersHeader />} orderNum="7" />
      <FAQ />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/halvenok/") {
        url
        phones
        ligal {
          text
        }
        notIndex
      }
      halvaOstatok: variable(name: "halvaOstatok") {
        value
      }
    }
  }
`
