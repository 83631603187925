import React from "react"
import clsx from "clsx"
import Container from "@ecom/ui/components/Container"
import { FAQBlock, FAQQuestion, FAQAnswer } from "@ecom/ui/components/FAQ"

import { QUESTIONS } from "./data"

import * as styles from "./faq.module.scss"

export default function Partners() {
  return (
    <section className={styles.root}>
      <Container className={styles.container}>
        <h2 className={clsx(styles.head, "head")}>Вопросы и ответы</h2>
        {QUESTIONS.map(([question, answer], i) => (
          <FAQBlock key={i} className={styles.block}>
            <FAQQuestion>{question}</FAQQuestion>
            <FAQAnswer>{answer}</FAQAnswer>
          </FAQBlock>
        ))}
      </Container>
    </section>
  )
}
